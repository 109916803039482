import { setDocFirestore } from '@/services/firebaseWrite'

// Accessing the 'settings' document in the 'settings' collection and getting its data
export const getSettings = async ($fire: any) => {
  return await $fire.firestore.collection('settings').doc('settings').get()
}

// Function to update the settings document in Firestore
export const updateSettings = async ($fire: any, body: any) => {
  return await setDocFirestore($fire, `settings/settings`, { ...body }, true)
}

// Fetching all documents from the 'settings' collection
export const getAllSettings = async ($fire: any) => {
  const settingsDoc = await $fire.firestore
    .collection('settings')
    .doc('settings')
    .get()
  let resp = null
  let version = 1
  if (settingsDoc.data().version) {
    version = settingsDoc.data().version.toMillis() / 1000
  }

  const url =
    process.env.NODE_ENV === 'production'
      ? process.env.OSCITY_ENDPOINTS_URL
      : process.env.LOCAL_ENDPOINTS_URL

  try {
    // Fetch the bundle from Firebase Hosting
    resp = await fetch(`/settingsBundle?version=${version}`)
    // Load the bundle contents into the Firestore SDK
    await $fire.firestore.loadBundle(resp.body)
  } catch (error) {
    // Fetch the bundle from Firebase Functions
    resp = await fetch(`${url}/settingsBundle?version=${version}`)
    // Load the bundle contents into the Firestore SDK
    await $fire.firestore.loadBundle(resp.body)
  }

  try {
    // Query the results from the cache
    const query = await $fire.firestore.namedQuery('latest-settings-query')
    return await query.get({ source: 'cache' })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return await $fire.firestore.collection('settings').get()
  }
}

export const getAvailableFeatures = async ($fire: any) => {
  try {
    const settingsDoc = await $fire.firestore
      .collection('settings')
      .doc('settings')
      .get()

    const data = settingsDoc.exists ? settingsDoc.data() : {}
    const availableFeatures = data?.availableFeatures ?? {}

    return {
      ...getDefaulFeatures(),
      ...availableFeatures,
    }
  } catch (error) {
    console.error('Error fetching available modules:', error)
    return getDefaulFeatures()
  }
}

const getDefaulFeatures = () => ({
  credentialManager: false,
})
