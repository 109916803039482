import { setDocFirestore } from '@/services/firebaseWrite'

// Retrieves the 'brand' document from the 'settings' collection.
export const getBrand = async ($fire: any) => {
  return await $fire.firestore.collection('settings').doc('brand').get()
}

// Updates the 'brand' document in the 'settings' collection.
export const updateBrand = async ($fire: any, body: any) => {
  return await setDocFirestore($fire, `settings/brand`, { ...body }, true)
}
