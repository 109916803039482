import { getAllSettings } from '@/services/Settings'

export const state = () => ({
  setting: {
    email_contact: '',
    redirectPath: '',
    isWithModules: true,
    isWithCertificates: true,
    hideInicioHeader: false,
    hideWhatIsHeader: false,
    hideCitizenHeader: false,
    hideRegisterLoginHeader: false,
    hideContentFooter: false,
    hideConnectWallet: false,
    hideFooter: false,
    withPayment: false,
    linkToChangePass: '',
    algoliaFacets: [
      'path',
      'title',
      'active',
      'parentPath',
      'config.showOnHomePage',
    ],
    walletForProcedures: false,
    operatorCanUnassign: false,
    simpleConclusion: false,
    nonWorkingDays: [],
    emailSuffix: '@os.city',
    seoSettings: {
      title: '',
      description: '',
      favicon: '',
    },
    platformLatLng: [16.49847115994006, -76.22896135],
    playlist: false,
    conjuction: false,
    activePrivateProcedures: false,
    activeShowAllFieldsAndFiles: false,
    permitEditForm: false,
    activeNewVersionTemplate: false,
    apiList: [],
    autocompleteFields: [],
    userIdentifierField: null,
    matchFieldUploadProcedures: {},
    truthProtest: '',
    shortPrivacyPolicy: '',
    fullPrivacyPolicy: '',
    isQuarkIdInstance: false,
    hideTimerPrevention: false,
    momentSetPaymentConfig: 'init',
    version: '',
    termsAndConditions: '',
  },
})

export const mutations = {
  // Updates the entire setting state
  SET_SETTING(state, setting) {
    state.setting = setting
  },
  // Allows updating a specific element in the settings state
  UPDATE_ELEMENT(state, { element, data }) {
    state.setting = { ...state.setting, [element]: data }
  },
}

export const actions = {
  // Fetches all settings from Firestore and commits them to the store
  async get({ commit }) {
    await getAllSettings(this.$fire).then((querySnapshot) => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const docId = doc.id
          const docData = doc.data()

          // Commit the appropriate settings based on document ID
          switch (docId) {
            case 'authConfig':
              commit('authconfig/SET_LOGIN_TYPES', docData, { root: true })
              break
            case 'brand':
              commit('brand/SET_BRAND', docData, { root: true })
              break
            case 'modules':
              commit('modules/SET_MODULES', [docData], { root: true })
              break
            case 'landingText':
              commit('landingtext/SET_LANDINGTEXT', docData, { root: true })
              break
            case 'certConfig':
              commit('certsConfig/SET_CERTS_CONFIG', docData, { root: true })
              break
            case 'procedures':
              commit('procedures/SET_SETTINGS', docData, { root: true })
              break
            default:
              // Commit general settings
              commit('SET_SETTING', { ...docData, id: docId })
              break
          }
        })
      }
    })
  },
}

export const getters = {
  // Check if email contact is set correctly
  isWithEmailContact(state) {
    return (
      state.setting.email_contact &&
      state.setting.email_contact.length > 3 &&
      state.setting.email_contact.includes('@')
    )
  },

  // Return the value of specific setting properties
  isWithModules(state) {
    return state.setting.isWithModules
  },
  isWithCertificates(state) {
    return state.setting.isWithCertificates
  },
  hideCitizenHeader(state) {
    return state.setting.hideCitizenHeader
  },
  hideWhatIsHeader(state) {
    return state.setting.hideWhatIsHeader
  },
  hideInicioHeader(state) {
    return state.setting.hideInicioHeader
  },
  hideFooter(state) {
    return state.setting.hideFooter
  },
  hideRegisterLoginHeader(state) {
    return state.setting.hideRegisterLoginHeader
  },
  hideContentFooter(state) {
    return state.setting.hideContentFooter
  },
  hideConnectWallet(state) {
    return state.setting.hideConnectWallet
  },
  withPayment(state) {
    return state.setting.withPayment
  },
  getLinkToChangePass(state) {
    return state.setting.linkToChangePass
  },
  walletForProcedures(state) {
    return state.setting.walletForProcedures
  },
  nonWorkingDays(state) {
    return state.setting.nonWorkingDays
  },
  emailSuffix(state) {
    return state.setting.emailSuffix || '@os.city'
  },
  seoSettings(state) {
    return state.setting.seoSettings
  },
  platformLatLng(state) {
    return state.setting.platformLatLng
  },
  operatorCanUnassign(state) {
    return state.setting.operatorCanUnassign
  },
  simpleConclusion(state) {
    return state.setting.simpleConclusion
  },
  getConjuctionValue(state) {
    return state.setting.conjuction
  },
  activePrivateProcedures(state) {
    return state.setting.activePrivateProcedures
  },
  activeShowAllFieldsAndFiles(state) {
    return state.setting.activeShowAllFieldsAndFiles
  },
  permitEditForm(state) {
    return state.setting.permitEditForm
  },
  activeNewVersionTemplate(state) {
    return state.setting.activeNewVersionTemplate
  },
  apiList(state) {
    return state.setting.apiList
  },
  autocompleteFields(state) {
    return state.setting.autocompleteFields
  },
  userIdentifierField(state) {
    return state.setting.userIdentifierField
  },
  truthProtest(state) {
    return state.setting.truthProtest
  },
  shortPrivacyPolicy(state) {
    return state.setting.shortPrivacyPolicy
  },
  fullPrivacyPolicy(state) {
    return state.setting.fullPrivacyPolicy
  },
  getQuarkIdInstanceValue(state) {
    return state.setting.isQuarkIdInstance
  },
  hideTimerPrevention(state) {
    return state.setting.hideTimerPrevention || false
  },
  momentSetPaymentConfig(state) {
    return state.setting.momentSetPaymentConfig || 'init'
  },
  version(state) {
    return state.setting.version || ''
  },
  termsAndConditions(state) {
    return state.setting.termsAndConditions || ''
  },
  algoliaFacets(state) {
    return state.setting.algoliaFacets || ''
  },
}
