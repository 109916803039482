import startsWith from 'lodash/startsWith'

export default function ({ store, redirect, route, $gtm }) {
  store.commit('loaderFull/CLOSE_LOADER')
  let title = route.fullPath.split('/')
  title = title[title.length - 1]
  if (!route?.fullPath?.includes('/admin/')) {
    $gtm.push({
      event: 'nuxtRoute',
      routeName: route.name,
      pageType: 'PageView',
      pageUrl: route.fullPath,
      pageTitle: title,
    })
  }
  if (
    route &&
    route.query &&
    route.query.redirect === 'true' &&
    !store.state.loggedIn &&
    !isPublic(route.path)
  ) {
    localStorage.prevUrl = route.fullPath
    localStorage.unlinkWallet = route.fullPath
    checkRedirect(route, redirect)
  } else if (!store.state.loggedIn && !isPublic(route.path)) {
    checkRedirect(route, redirect)
  } else if (
    route &&
    route.query &&
    route.query.redirect === 'true' &&
    !store.state.loggedIn &&
    startsWith(route.path, '/email-action-handler')
  ) {
    localStorage.prevUrl = route.fullPath
    localStorage.unlinkWallet = route.fullPath
    return redirect('/auth/login')
  } else if (
    route &&
    route.query &&
    !store.state.loggedIn &&
    startsWith(route.path, '/login')
  ) {
    checkRedirect(route, redirect)
  }
}
function checkRedirect(route, redirect) {
  const isLoginFuncionary =
    process.env.FB_PROJECT_ID === 'os-sandbox-nuevo-leon' ||
    process.env.FB_PROJECT_ID === 'os-gobierno-de-nuevo-leon'

  const isPathFunc = isPathFuncionary(route.path)
  if (isPathFunc === true && isLoginFuncionary === true) {
    return redirect('/auth/login-funcionarios')
  } else if (isPathFunc === true && isLoginFuncionary === false) {
    return redirect('/auth/login')
  } else {
    return redirect('/')
  }
}

function isPublic(path) {
  if (
    path === '/' ||
    startsWith(path, '/login') ||
    startsWith(path, '/auth/login') ||
    startsWith(path, '/auth/login-funcionarios') ||
    startsWith(path, '/auth/register') ||
    startsWith(path, '/auth/resetPassword') ||
    startsWith(path, '/auth/linkWallet') ||
    startsWith(path, '/auth/messageSSO') ||
    startsWith(path, '/contact') ||
    startsWith(path, '/privacy') ||
    startsWith(path, '/register') ||
    startsWith(path, '/password-reset') ||
    startsWith(path, '/email-action-handler') ||
    startsWith(path, '/reset-password') ||
    startsWith(path, '/server-error') ||
    startsWith(path, '/autenticar-error') ||
    startsWith(path, '/what-is') ||
    startsWith(path, '/faq') ||
    startsWith(path, '/digital-citizen') ||
    startsWith(path, '/digital-citizen-levels') ||
    startsWith(path, '/terms-of-service') ||
    startsWith(path, '/notice-of-privacy') ||
    startsWith(path, '/citizen/certificates/digital-identity/') ||
    startsWith(path, '/citizen/certificates/form-certs/') ||
    startsWith(path, '/finalDocument/') ||
    startsWith(path, '/modules') ||
    startsWith(path, '/home') ||
    startsWith(path, '/digital-services') ||
    startsWith(path, '/mercadopago') ||
    startsWith(path, '/predial') ||
    startsWith(path, '/persona-moral') ||
    startsWith(path, '/playlist') ||
    startsWith(path, '/citizen/certificates/verification') ||
    startsWith(path, '/verificador') ||
    startsWith(path, '/user-guide') ||
    startsWith(path, '/user-guide-functionary')
  ) {
    return true
  } else {
    return false
  }
}
function isPathFuncionary(path) {
  if (startsWith(path, '/admin')) {
    return true
  } else {
    return false
  }
}
